<template>
	<v-sheet>
		<v-card flat class="mx-auto" width="600">
			<v-card-title>Reset Password</v-card-title>
			<template v-if="sent">
				<v-card-text>
					Thank you. If an account is found for this email address, you will
					receive an email with a reset password link
				</v-card-text>
			</template>
			<template v-else>
				<v-card-text class="pa-2">
					<v-text-field filled label="Email Address" v-model="email"></v-text-field>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="submit" color="primary" :disabled="!email"
						>Submit</v-btn
					>
				</v-card-actions>
			</template>
		</v-card>
	</v-sheet>
</template>

<script>
	import firebase from "firebase";
	export default {
		name: "ForgotPassword",
		data: () => {
			return {
				email: null,
				sent: false
			};
		},
		methods: {
			async sendEmail() {
				const functions = firebase.app().functions("europe-west1");
				const sendEmail = functions.httpsCallable("forgotPassword");
				const data = {
					email: this.email, 
					url: window.location.origin
				};
				return sendEmail(data);
			},
			submit() {
                this.sendEmail().then( () => {
                    this.sent = true;
                })
            }
		}
	};
</script>
